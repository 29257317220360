<template>
    <div>
        <div class="w-full is-flex relative job-list-wrap ">
            <div v-if="!useJobStore().selected_single_job.id" 
                class="job-list-wrap absolute bg-slate-50 w-full h-full overflow-y-scroll"
                style="z-index:999;">
                <div class="border-b mb-2 is-flex text-center">
                    <span> 
                      <i class="fas fa-wrench fa-xs"></i>
                      <b>
                        Lista puneve  
                      </b>
                    </span>
                    <a @click.prevent="toggle_list_filter()" href="" class="button is-small ml-auto">
                        <i class="fa-solid fa-filter"></i>
                    </a>
                </div>
                <div class="">
                    <div v-for="job in useJobStore().job_list" :key="job"
                        @click="useJobStore().select_single_job(job)"
                        :class="{
                            'bg-amber-50': job.status_id == 1,
                            'bg-cyan-50': job.status_id == 2,
                            'bg-indigo-50': job.status_id == 3,
                            'bg-teal-50': job.status_id == 4
                        }"
                        class="border mb-3 bg-white flex text-14px cursor-pointer hover:bg-slate-50">
                        <div class="flex items-center px-3 w-14">
                            {{job.id}}
                        </div>
                        <div class="flex flex-col flex-grow border-l border-r">
                            <div class="flex border-b">
                                <div class="w-48 border-r px-1 flex flex-col">
                                    <span class="text-slate-400 text-8px">Data e fillimit</span>
                                    <span> {{useProcessStore().date_filter(job.min_date)}} </span>
                                </div>
                                <div class="w-48 border-r px-1 flex flex-col">
                                    
                                </div>
                                <div class="w-48 border-r px-1 flex flex-col">
                                    <span class="text-slate-400 text-8px">Perditesuar nga</span>
                                    <span> {{job.updated_by_name}} </span>
                                </div>
                                <!-- <pre> {{job}} </pre> -->
                            </div>
                            <div class="flex">
                                <div class="w-48 border-r px-1 flex flex-col">
                                    <span class="text-slate-400 text-8px">Data e fillimit</span>
                                    <span> {{useProcessStore().date_filter(job.max_date)}} </span>
                                </div>
                                <div class="w-48 border-r px-1 flex flex-col">
                                    <span class="text-slate-400 text-8px">Pergjegjesi</span>
                                    <span> {{job.assigned_to_name}} </span>
                                </div>
                                <div class="w-48 border-r px-1 flex flex-col">
                                    <span class="text-slate-400 text-8px">Krijuar nga</span>
                                    <span> {{job.user_name}} </span>
                                </div>
                            </div>
                        </div>
                        <div :class="{
                                'bg-amber-100': job.status_id == 1,
                                'bg-cyan-100': job.status_id == 2,
                                'bg-indigo-100': job.status_id == 3,
                                'bg-teal-100': job.status_id == 4
                            }"
                             class="flex items-center justify-center px-3 text-semibold w-28">
                            {{
                                job.status_id == 1 ? 'Pending' : 
                                job.status_id == 2 ? 'I planifikuar' : 
                                job.status_id == 3 ? 'I procesuar' : 
                                job.status_id == 4 ? 'I perfunduar' : ''
                            }} 
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="bg-slate-50 w-full">
                <div class="flex justify-between mb-3">
                    <span class="flex-grow w-1/3">
                         <span @click.prevent="useJobStore().selected_single_job = {}" class="border rounded px-2 py-1 border-slate-400 cursor-pointer">
                            <i class="fa-solid fa-chevron-left"></i>
                         </span>
                    </span>
                    <span class="flex-grow text-center w-1/3">
                        Job:
                        <span class="font-semibold ">{{ useJobStore().selected_single_job.id }}</span>
                    </span>
                    <span class="flex-grow text-right w-1/3">
                        <span
                            :class="{'bg-green-100': useJobStore().selected_single_job.status_id == 4}"
                             class="border rounded px-2 py-1 border-slate-400">
                            Statusi: {{
                                useJobStore().selected_single_job.status_id == 1 ? 'Pending' : 
                                useJobStore().selected_single_job.status_id == 2 ? 'I planifikuar' : 
                                useJobStore().selected_single_job.status_id == 3 ? 'I procesuar' : 
                                useJobStore().selected_single_job.status_id == 4 ? 'I perfunduar' : ''
                            }} 
                        </span>
                    </span>
                </div>
                <div class=" w-full">
                    <p v-if="useJobStore().loading_single_job" class="has-text-centered">
                        Loading ...
                    </p>
                    <div v-else>
                        <div class="bg-white w-full rounded border border-slate-500 mb-2 text-xs  flex  text-12px">
                            <div class="flex flex-col flex-grow">
                                <div class="flex border-b">
                                    <div class="w-48 border-r px-1 flex flex-col">
                                        <span class="text-slate-400 text-8px">Data e fillimit</span>
                                        <span> {{useProcessStore().date_filter(useJobStore().selected_single_job.min_date)}} </span>
                                    </div>
                                    <div class="w-48 border-r px-1 flex flex-col">
                                        <span class="text-slate-400 text-8px">Statusi</span>
                                        <span> 
                                            {{
                                                useJobStore().selected_single_job.status_id == 1 ? 'Pending' : 
                                                useJobStore().selected_single_job.status_id == 2 ? 'I planifikuar' : 
                                                useJobStore().selected_single_job.status_id == 3 ? 'I procesuar' : 
                                                 useJobStore().selected_single_job.status_id == 4 ? 'I perfunduar' : ''
                                            }} 
                                        </span>
                                    </div>
                                    <div class="w-48 border-r px-1 flex flex-col">
                                        <span class="text-slate-400 text-8px">Perditesuar nga</span>
                                        <span> {{useJobStore().selected_single_job.updated_by_name}} </span>
                                    </div>
                                    <!-- <pre> {{useJobStore().selected_single_job}} </pre> -->
                                </div>
                                <div class="flex">
                                    <div class="w-48 border-r px-1 flex flex-col">
                                        <span class="text-slate-400 text-8px">Data e fillimit</span>
                                        <span> {{useProcessStore().date_filter(useJobStore().selected_single_job.max_date)}} </span>
                                    </div>
                                    <div class="w-48 border-r px-1 flex flex-col">
                                        <span class="text-slate-400 text-8px">Pergjegjesi</span>
                                        <span> {{useJobStore().selected_single_job.assigned_to_name}} </span>
                                    </div>
                                    <div class="w-48 border-r px-1 flex flex-col">
                                        <span class="text-slate-400 text-8px">Krijuar nga</span>
                                        <span> {{useJobStore().selected_single_job.user_name}} </span>
                                    </div>

                                </div>
                            </div>
                            <div v-if="useJobStore().selected_single_job.status_id == 2"
                                 @click.prevent="start_process_job(useJobStore().selected_single_job)"
                                class="flex w-48 items-center justify-center border-r bg-green-100 cursor-pointer text-14px font-semibold">
                                Proceso
                            </div>
                        </div>
                        <span> Sherbime </span>
                        <div v-for="doc_serv in useJobStore().selected_single_job.document_services" :key="doc_serv.guid"
                            class="bg-white border rounded border-slate-200 mb-2">
                            <!-- <pre> {{doc_serv}} </pre> -->
                            <div class="is-flex is-flex-direction-column border border-slate-700 rounded">
                                <div class="is-flex">
                                    <div class="flex flex-col is-flex-grow-1 text-xs">
                                        <div class="flex border-b p-1">
                                            <div class="w-1/3 border-r">
                                                <span class="text-slate-400">
                                                    <i class="" :class="doc_serv.service_icon"></i>
                                                    {{doc_serv.service_name}}
                                                </span>
                                            </div>
                                            <div class="w-1/2 pl-1">
                                                {{doc_serv.product_name}}
                                            </div>
                                        </div>
                                        <div class="flex border-b px-1">
                                            <div class="w-1/3 flex justify-center flex-col">
                                                <span class="text-12px text-slate-400">
                                                    Kerkuar per date
                                                </span>
                                                <span>
                                                    {{useProcessStore().date_filter(doc_serv.requested_for)}}
                                                </span>
                                            </div>
                                            <div class="w-1/3 flex justify-center flex-col border-l pl-1">
                                                <span class="text-12px text-slate-400 ">
                                                    Nga (origjina)
                                                </span>
                                                <span>
                                                    {{doc_serv.origin_name}} <br>
                                                    {{doc_serv.origin_address}}
                                                </span>
                                            </div>
                                            <div class="w-1/3 flex justify-center flex-col border-l pl-1">
                                                <span class="text-12px text-slate-400">
                                                    Tek (destinacioni)
                                                </span>
                                                <span>
                                                    {{doc_serv.destination_name}} - {{doc_serv.destination_phone}} <br>
                                                    {{doc_serv.destination_address}}
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="doc_serv.note" class="flex flex-col p-1">
                                            <span class="text-12px text-slate-400">
                                                Shenim
                                            </span>
                                            <span>
                                                {{doc_serv.note}}
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="useJobStore().selected_single_job.status_id > 2" class="flex cursor-pointer">
                                        <div v-if="!doc_serv.completed_status"
                                            @click="start_change_doc_service_status(doc_serv, useJobStore().selected_single_job)" 
                                            class="bg-sky-50 p-3 is-flex is-align-items-center">
                                            <i class="fa-solid fa-check"></i>
                                        </div>
                                        <div v-if="doc_serv.completed_status == 1"
                                            class="bg-green-50 p-3 is-flex is-align-items-center">
                                            <i class="fa-solid fa-check"></i>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="doc_serv.completed_status == 1"
                                    class="bg-green-50 text-xs p-1">
                                    <div v-if="doc_serv.completed_status == 1">
                                        Konfirmuar me: {{useProcessStore().date_filter(doc_serv.completed_at)}}
                                        <br>
                                        Konfirmuar nga: {{doc_serv.completed_by_name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { inject } from "vue";
    import { useJobStore } from '@/stores/job'
    import { useProcessStore } from '@/stores/process'
    const toast = inject("toast");
    function toggle_list_filter(){}
    async function start_process_job(job){
        if (confirm("Jeni te sigurt qe doni procesoni kete flete pune?")) {
            await useJobStore().process_job(job)
            .then(r => {
                if(r.data == 1){
                    useJobStore().job_list = useJobStore().job_list.map(j => {
                        if(j.guid == job.guid){
                            j.status_id = 3
                        }
                        return j
                    })
                }
                toast.show('Perditesimi u ruajt me sukses.', { ...{ type: 'success', duration: 4000 } });
            })
        }
    }
    async function start_change_doc_service_status(doc_serv, job){
        if (confirm("Jeni te sigurt qe doni konfirmoni kete sherbim?")) {

            await useJobStore().change_doc_service_status(doc_serv, job).then( async res => {
                if(res.data.service_updated > 0){
                    useJobStore().job_list = useJobStore().job_list.map(j => {
                        if(j.guid == job.guid){
                            j.document_services = res.data.job_services
                            if(res.data.job_completed > 0){
                                // Update the status of the job
                                j.status_id = 4
                            }
                        }
                        return j
                    })
                    // await useJobStore().get_job_services(job).then(r => {})
                    toast.show('Perditesimi u ruajt me sukses.', { ...{ type: 'success', duration: 4000 } });
                }
            })
        }
    }
</script>

<script>
    export default {
        
    }
</script>

<style scoped>
    .job-list-wrap{
        height: calc(100vh - 85px);
        /* overflow-y:hidden; */
    }

</style>
